import React, { useEffect, useState } from 'react'
import Slider from '../components/slider'
import Item from '../components/item'
import './sitel-dnevna.scss';

import transition from '../icons/animation.mp4';
import { useSelector } from 'react-redux';
import BalkanMap from './map-balkan';
import MacedoniaMap from './map-mk';
import Forecast3Days from './3days-forecast';
import { IconPlayerPlay } from '@tabler/icons-react';

import StartEndImage from '../icons/start_end.jpg';
import Dnevna16Tridnevna from '../icons/dnevna16_tridnevna.mp4';

const TemplateSitelDnevna16Tomorrow = () => {
	const [ slideIndex, setSlideIndex ] = useState()
	const channel = new BroadcastChannel('record');
	const weather = useSelector(state => state.weather.collection)
	
	// Listen for messages on "record".
	channel.onmessage = function(e) {
		if ( e.data === 'start')
			setSlideIndex(0)
	};

	const handleDownload = (e) => {
		setSlideIndex(0);

		// window.open('/templates/download', "_blank", "width=400,height=200,menubar=no,location=no,status=no,titlebar=no,scrollbars=no")
		e.currentTarget.remove();
	}

	useEffect( () => {
		setTimeout( () => {
			setSlideIndex(0);
		}, 5000 )
	}, [] )
	
	return (
		<div className="template sitel-dnevna-16">
			<Slider slideIndex={slideIndex} setSlideIndex={setSlideIndex}>
				{/* <button className='btn btn-primary start-template download' onClick={(e) => handleDownload(e) }>
					<IconPlayerPlay size={48} stroke={3} />
				</button> */}

				<Item duration={'space'}>
					<div className="image-bg">
						<img src={StartEndImage} alt="BG" />
					</div>
				</Item>

				<Item duration={'space'}>
					<div className="transition-bg">
						<video muted loop id="transition">
							<source src={transition} />
						</video>
					</div>
				</Item>

				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div> */}

					<BalkanMap data={weather} />
				</Item>
				
				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div> */}

					<MacedoniaMap data={weather} />
				</Item>
				
				<Item duration={'space'}>
					{/* <div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div> */}

					<Forecast3Days data={weather} bg={Dnevna16Tridnevna} />
				</Item>

				<Item duration={'space'}>
					<div className="transition-bg">
						<video muted id="transition">
							<source src={transition} />
						</video>
					</div>
				</Item>
			</Slider>
		</div>
	)
}

export default TemplateSitelDnevna16Tomorrow